import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
      <b>
        &copy; {new Date().getFullYear()} Ceunen.io
      </b>
      <br/>
      <b>
      BE 0764.938.040
      </b>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
